export const INFO = [
	{
		title: "",
		text: `<p>Я умею сбрасывать рамки, установленные социумом. Умею жить по своим законам. Но я не один такой – нас, единомышленников, немало. Нас объединяют те же принципы, которые сформировали и ДНК бренда Jaecoo – J-SYSTEM</p>`,
		image: "0",
		position: "horizontalReverse",
		background: "#0D171A",
	},
	{
		title: "J-NATURE",
		text: `<p>Природа помогает мне раскрыть мои внутренние силы. Я люблю выезжать на природу для активного отдыха, хобби, или просто в компании друзей.</p>`,
		image: "1",
		position: "",
		background: "",
	},
	{
		title: "",
		text: ``,
		image: "3",
		position: "",
		background: "#0D171A",
	},
	{
		title: "J-SPORT",
		text: `<p>Спорт, физическая активность – это то, что помогает мне продолжать движение. Помогает жить активно и разнообразно</p>`,
		image: "2",
		position: "",
		background: "",
	},
	{
		title: "J-TRAVEL",
		text: `<p>Jaecoo – мой лучший навигатор в исследовании мира и его бесконечных возможностей и впечатлений</p>`,
		image: "4",
		position: "",
		background: "",
	},
]

import Banner from "../../blocks/Banner/Banner"
import Header from "../../blocks/Header/Header"
import styles from "./Home.module.scss"
import Info from "../../blocks/Info/Info"
import {INFO} from "../../../infoConfig"
import Benefit from "../../blocks/Benefit/Benefit"

export default function Home() {
	return (
		<>
			<div className={styles.headerWrapper}>
				<Header />
				<Banner />
			</div>
			<div className={styles.main}>
				{INFO.slice(0, 2).map(({title, text, image, position, background}) => (
					<Info title={title} text={text} image={image} position={position} background={background} />
				))}
				<Benefit
					title='Настоящий компаньон'
					text='Jaecoo – мой надежный спутник на пути в мир настоящего общения, полезного и приятного взаимодействия'
					background='#0D171A'
				/>
				<picture>
					<source className={styles.image} srcSet={"/images/manifest.jpg"} width={1920} height={728} media='(min-width: 768px)' />
					<img className={styles.image} src={`/images/manifest-m.jpg`} alt='' width={600} height={230} loading='lazy' />
				</picture>
				<Benefit
					title='J-CITY'
					text='В городе проходит основная часть моей жизни. Именно здесь я могу жить на полную в разных режимах. Деловые встречи, торговые центры, концерты и фестивали, клубы, кафе и рестораны – я везде чувствую себя естественно и непринужденно'
				/>
				{INFO.slice(2, 4).map(({title, text, image, position, background}) => (
					<Info title={title} text={text} image={image} position={position} background={background} />
				))}
				<Benefit
					title='Настоящий партнер'
					text='Jaecoo – позволяет мне максимально сконцентрироваться перед тренировкой или соревнованием, а после – расслабиться и заслуженно отдохнуть'
					background='#0D171A'
				/>
				{INFO.slice(4).map(({title, text, image, position, background}) => (
					<Info title={title} text={text} image={image} position={position} background={background} />
				))}
				<Benefit
					title='Настоящий навигатор'
					text='Путешествия – лучший способ раздвинуть границы, наслаждаться реальностью. Я всегда рад отправиться в короткую поездку или в увлекательное путешествие в другие города'
					background='#0D171A'
				/>
			</div>
		</>
	)
}

import Container from "../../UI/Container/Container"
import styles from "./Benefit.module.scss"

export default function Benefit({title, text, background}) {
	return (
		<section style={background ? {background, color: "#6C7C80"} : {}}>
			<Container noPaddingTop noPaddingBottom>
				<div className={styles.wrapper}>
					<h2 className={styles.title} style={background ? {color: "#FFFFFF"} : {}}>
						{title}
					</h2>
					<p className={styles.text}>{text}</p>
				</div>
			</Container>
		</section>
	)
}

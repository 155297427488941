import Container from "../../UI/Container/Container"
import styles from "./Info.module.scss"

export default function Info({title, text, background, image, position}) {
	return (
		<section style={background ? {background, color: "#6C7C80"} : {}}>
			<Container noPaddingTop noPaddingBottom>
				<div className={`${styles.container} ${styles[`${position}`]}`}>
					{title || text ? (
						<div className={styles.wrapper}>
							<h2 className={styles.title}>{title}</h2>
							<div className={styles.wrapper} dangerouslySetInnerHTML={{__html: text}}></div>
						</div>
					) : (
						""
					)}
					<img className={styles.image} src={`/images/info/${image}.jpg`} alt='' />
				</div>
			</Container>
		</section>
	)
}

import Container from "../../UI/Container/Container"
import styles from "./Footer.module.scss"

export default function Footer() {
	return (
		<footer>
			<Container noPaddingBottom noPaddingTop>
				<div className={styles.wrapper}>
					<div className={styles.policyBlock}>
						<p className={styles.copyright}>&copy; {new Date().getFullYear()}, JAECOO MAXIMUM</p>
						<a className={styles.link} href='/privacy-policy' target='_blank'>
							Правовая информация
						</a>
					</div>
					<p className={styles.info}>
						Общество с ограниченной ответственностью «Максимум Страхование»
						<br />
						ИНН 7804569826
						<br />
						ОГРН 1167847261174
						<br />
						Данный сайт носит информационный характер и ни при каких условиях материалы и цены размещенные на сайте не являются публичной офертой.
					</p>
				</div>
			</Container>
		</footer>
	)
}

import styles from "./Logo.module.scss"

export default function Logo({isBlack}) {
	return (
		<a href='/' className={styles.wrapper}>
			<img alt='логотип' className={styles.image} src={`/images/logo${isBlack ? "-black" : ""}.svg`} width={120} height={28} />
			<div className={styles.divider} />
			<p style={isBlack ? {color: "black"} : {}}>МАКСИМУМ ОБВОДНЫЙ</p>
		</a>
	)
}
